



































import MNotificationVue from "@/mixins/MNotification.vue";
import { FILENAME_BOTTOM_PRICE_REPORT_TEMPLATE } from "@constant/global.constant";
import { fileServices } from "@service/file.service";
import { productBottomPriceService } from "@service/product-bottom-price.service";
import Vue from "vue";

export default Vue.extend({
  name: "ProductUpdateBottomPrice",
  mixins: [
    MNotificationVue
  ],
  data() {
    return {
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
      },
      fileList: [] as any,
      uploadProcess: false,
      loading: {
        download: false
      }
    };
  },
  computed: {
    uploadUrl(): string {
      return productBottomPriceService.getUploadUrl();
    }
  },
  methods: {
    uploadReportuploadReportBottomPrice(file: FormData): Promise<boolean> {
      return productBottomPriceService.uploadReportBottomPrice(file);
    },
    getFile(filename: string): Promise<Blob> {
      return fileServices.getFile(filename);
    },
    handleChangeAttachment(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
      if (info.file.status !== "uploading") {
        this.uploadProses = true;
      }
      if (info.file.status === "done") {
        this.uploadProses = false;
        this.$message.success(this.$t("notif_file_upload_successfully", { filename: info.file.name }).toString());
      } else if (info.file.status === "error") {
        this.uploadProses = true;
        this.$message.error(this.$t("notif_file_upload_failed", { filename: info.file.name }).toString());
      }
    },
    async downloadTemplate(): Promise<void> {
      try {
        this.loading.download = true;
        const res = await this.getFile(FILENAME_BOTTOM_PRICE_REPORT_TEMPLATE);
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TEMPLATE_BOTTOM_PRICE_REPORT.xlsx"); //or any other extension
        link.click();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.download = false;
      }
    },
  }
});
