var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-upload-dragger",
            {
              attrs: {
                name: "file",
                multiple: false,
                action: _vm.uploadUrl,
                headers: _vm.headers,
                "file-list": _vm.fileList,
                accept: ".xlsx"
              },
              on: { change: _vm.handleChangeAttachment }
            },
            [
              _c(
                "p",
                { staticClass: "ant-upload-drag-icon" },
                [_c("a-icon", { attrs: { type: "inbox" } })],
                1
              ),
              _c("p", { staticClass: "ant-upload-text" }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_upload_file_drag")) + " ")
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                icon: "download",
                loading: _vm.loading.download
              },
              on: { click: _vm.downloadTemplate }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download_report_template")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }